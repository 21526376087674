* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Reem Kufi", sans-serif !important;
}
img {
  max-width: 100%;
  height: auto;
}
.App {
  min-height: 100vh;
  width: 85vw;
  max-width: 1900px;
  margin: 0rem auto;
}
body {
  max-width: 100%;
  overflow-x: hidden;
}

body {
  background-color:lightcyan;
}
.primary-text {
  font-size: clamp(1rem, 3vw, 1.5rem);
  max-width: 500px;
  color:	#87CEEB;
  margin: 1.5rem 0rem;
}
.gematria-section-top p {
  text-align: center;
  max-width: 600px !important;
}
.gematria-section-top h1 {
  max-width: 700px !important;
}
.gematria-section-top {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: lightcyan;
}
.textarea-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-left: 10%;
  margin-right: 10%;
}

@media (max-width: 1000px) {

}
/* მობილურის ვერსია */
@media (max-width: 800px) {
  .nav-logo-container {
    max-width: 140px;
  }
  .navbar-links-container {
    display: none;
  }
  .primary-heading {
    text-align: center;
    max-width: 90%;
  }
  .primary-text {
    text-align: center;
    max-width: 80%;
  }
  .App {
    max-width: 95vw;
  }
}
